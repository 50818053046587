<template>
  <RecordViewWrapper>
    <sdPageHeader title="Data Kepemilikan Saham">
      <template #subTitle>
        <sdButton
          class="btn-add_new"
          size="default"
          type="primary"
          v-if="checkPermission('create permintaan eksternal data kepemilikan saham plnbb group')"
        >
          <router-link to="/eksternal/kepemilikan-saham-add">
            <sdFeatherIcons type="plus" size="14" /> Tambah</router-link
          >
        </sdButton>
      </template>
      <template #buttons>
        <div class="search-box">
          <span class="search-icon">
            <sdFeatherIcons type="search" size="14" />
          </span>
          <a-input
            @change="handleSearch"
            v-model:value.trim="formState.searchItem"
            type="text"
            name="recored-search"
            placeholder="Search Here"
          /></div
      ></template>
    </sdPageHeader>

    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div v-if="isLoading" class="spin">
              <a-spin />
            </div>

            <div v-else>
              <TableWrapper class="table-data-view table-responsive">
                <a-table
                  :pagination="{ pageSize: 10, showSizeChanger: true }"
                  :dataSource="dataSource"
                  :columns="columns"
                />
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>

      <a-modal v-model:visible="modalState.visible" title="Konfirmasi">
        <a-form
          layout="horizontal"
          :model="formModalState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="modalRules"
          id="unapproveForm"
          @finish="doUnapprove"
        >
          Apakah Anda yakin ingin melakukan unapprove kepemilikan saham {{ modalState.perusahaan }}
          {{ modalState.month }} {{ modalState.year }}?
          <a-form-item name="alasan" label="Alasan">
            <a-input v-model:value="formModalState.alasan" placeholder="Masukkan Alasan" />
          </a-form-item>
        </a-form>
        <template #footer>
          <a-button key="back" @click="handleCancel">Batal</a-button>
          <a-button class="sDash_form-action__btn" type="primary" size="large" htmlType="submit" form="unapproveForm">
            {{ modalState.isLoading ? 'Harap Menunggu...' : 'Unapprove' }}
          </a-button>
        </template>
      </a-modal>
    </Main>
  </RecordViewWrapper>
</template>

<script>
import { RecordViewWrapper } from '../../../components/crud/style';
import { computed, onMounted, reactive } from 'vue';
import { Main, TableWrapper } from '../../styled';
import { useStore } from 'vuex';
import { Modal } from 'ant-design-vue';
import { DataService } from '@/config/dataService/dataService';
import { getItem } from '@/utility/localStorageControl';

const columns = [
  {
    title: 'Bulan',
    dataIndex: 'month',
    key: 'month',
  },
  {
    title: 'Perusahaan',
    dataIndex: 'perusahaan_id',
    key: 'perusahaan_id',
  },
  {
    title: 'Bidang Usaha',
    dataIndex: 'bidang_usaha_id',
    key: 'bidang_usaha_id',
  },
  {
    title: 'Jumlah Saham',
    dataIndex: 'jumlah_saham',
    key: 'jumlah_saham',
    align: 'right',
  },
  {
    title: 'Pemilik Saham Langsung',
    dataIndex: 'pemilik_langsung',
    key: 'pemilik_langsung',
  },
  {
    title: 'Status Saham',
    dataIndex: 'status_saham',
    key: 'status_saham',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Actions',
    dataIndex: 'action',
    key: 'action',
    width: '90px',
  },
];

const ViewPage = {
  name: 'ViewPage',
  components: { RecordViewWrapper, Main, TableWrapper },
  setup() {
    const { state, dispatch } = useStore();
    const crud = computed(() => state.crud.data);
    const isLoading = computed(() => state.crud.loading);
    const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const profile = getItem('profile_plnbb');
    const permissions = getItem('permissions');

    const modalState = reactive({
      isLoading: false,
      visible: false,
      perusahaan: '',
      month: '',
      year: null,
    });

    const formModalState = reactive({
      id: null,
      alasan: '',
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    onMounted(() => {
      getData();
    });

    const getData = () => {
      dispatch('axiosCrudGetData', 'kepemilikan-saham');
    };

    const handleDelete = (id, month, year, perusahaan) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin menghapus data kepemilikan saham ${months[month]} ${year} ${perusahaan}?`,
        okText: 'Hapus',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataDelete', {
            id,
            url: 'kepemilikan-saham',
            getData: () => {
              dispatch('axiosCrudGetData', 'kepemilikan-saham');
            },
          });
        },
      });
    };

    const formState = reactive({
      searchItem: '',
    });

    const dataSource = computed(() =>
      crud.value.length
        ? crud.value.map((data, key) => {
            const { id, month, year, perusahaan, jumlah_saham, pemilik_langsung, status_saham, status } = data;

            let color;

            switch (status) {
              case 'CRTD':
                color = 'blue';
                break;
              case 'UPDT':
                color = 'orange';
                break;
              case 'REL':
                color = 'green';
                break;
              case 'UNREL':
                color = 'volcano';
                break;
              case 'DEL':
                color = 'red';
                break;
            }

            let update = checkPermission('update asdf') ? (
              <router-link class="edit" to={`/eksternal/kepemilikan-saham-edit/${id}`}>
                <sdFeatherIcons type="edit" size={14} title="Ubah" />
              </router-link>
            ) : null;

            let approve = checkPermission('approve asdf') ? (
              <a class="edit" onClick={() => handleApprove(id, perusahaan.name, month, year)}>
                <sdFeatherIcons type="check-square" size={14} title="Approve" />
              </a>
            ) : null;

            let del = checkPermission('delete asdf') ? (
              <router-link class="delete" onClick={() => handleDelete(id, month, year, perusahaan.name)} to="#">
                <sdFeatherIcons type="trash-2" size={14} title="Hapus" />
              </router-link>
            ) : null;

            let unapprove = checkPermission('approve asdf') ? (
              <a class="edit" onClick={() => handleUnapprove(id, perusahaan.name, month, year)}>
                <sdFeatherIcons type="rotate-ccw" size={14} title="Unapprove" />
              </a>
            ) : null;

            return {
              key: key + 1,
              month: months[month] + '-' + year,
              perusahaan_id: perusahaan ? perusahaan['name'] : '',
              bidang_usaha_id: perusahaan && perusahaan['bidang_usaha'] ? perusahaan['bidang_usaha']['name'] : '',
              jumlah_saham,
              pemilik_langsung,
              status_saham,
              status: <a-tag color={color}>{status}</a-tag>,
              action: (
                <div class="table-actions">
                  {status != 'REL' ? (
                    <>
                      {update}
                      {approve}
                      {del}
                    </>
                  ) : (
                    { unapprove }
                  )}
                </div>
              ),
            };
          })
        : [],
    );

    const handleSearch = () => {
      dispatch('axiosDataSearch', { url: 'kepemilikan-saham', filter: formState.searchItem });
    };

    const handleApprove = (id, perusahaan, month, year) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin melakukan approve kepemilikan saham ${perusahaan} ${months[month]} ${year}?`,
        okText: 'Approve',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataApprove', {
            id,
            url: 'approve-kepemilikan-saham',
            getData: () => {
              dispatch('axiosCrudGetData', 'kepemilikan-saham');
            },
          });
        },
      });
    };

    const handleUnapprove = (id, perusahaan, month, year) => {
      formModalState.id = id;
      modalState.perusahaan = perusahaan;
      modalState.month = months[month];
      modalState.year = year;
      modalState.visible = true;
    };

    const modalRules = {
      alasan: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    const handleCancel = () => {
      modalState.visible = false;
    };

    const doUnapprove = () => {
      modalState.isLoading = true;

      DataService.post('unapprove-kepemilikan-saham/' + formModalState.id, { alasan: formModalState.alasan }).then(
        () => {
          modalState.visible = false;
          modalState.isLoading = false;
          formModalState.id = null;
          formModalState.alasan = '';

          dispatch('axiosCrudGetData', 'kepemilikan-saham');
        },
      );
    };

    const checkPermission = permission => {
      if (profile.roles[0].name == 'Super Admin') {
        return true;
      }

      return permissions.includes(permission);
    };

    return {
      formState,
      columns,
      isLoading,
      crud,
      dataSource,
      handleDelete,
      handleSearch,
      labelCol,
      wrapperCol,
      modalState,
      handleCancel,
      doUnapprove,
      formModalState,
      modalRules,
      checkPermission,
    };
  },
};

export default ViewPage;
</script>
